import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import jsCookie from "js-cookie";

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useGetMyUser = () => {
  const getMyUserRequest = async () => {
    const token = localStorage.getItem("admin");

    const response = await fetch(`${API_BASE_URL}/my/user`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get user");
    }

    return response.json();
  };

  const {
    data: currentUser,
    isLoading,
    error,
  } = useQuery("fetchCurrentUser", getMyUserRequest);

  return { currentUser, isLoading };
};

export const useCreateMyUser = () => {

  const createMyUserRequest = async (user) => {
    const token = localStorage.getItem("admin");
    const response = await fetch(`${API_BASE_URL}/my/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    if (!response.ok) {
      throw new Error("Failed to create user");
    }
  };

  const {
    mutateAsync: createUser,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(createMyUserRequest);

  return {
    createUser,
    isLoading,
    isError,
    isSuccess,
  };
};
export const useUpdateMyUser = () => {

  const updateMyUserRequest = async (formData) => {
    const token = localStorage.getItem("admin");

    try {
      const response = await axios.put(`${API_BASE_URL}/my/user`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Failed to update user");
    }
  };

  const {
    mutateAsync: updateUser,
    isLoading,
    isSuccess,
    error,
    reset,
  } = useMutation(updateMyUserRequest);

  if (isSuccess) {
    toast.success("User Profile updated");
  }

  if (error) {
    toast.error(error.toString());
    reset();
  }

  return { updateUser, isLoading };
};

export const getAdminDetails = async () => {
  try {
    const token = localStorage.getItem("admin");

    if (!token) {
      return;
    }
    const response = await axios.get(`${API_BASE_URL}/users/user-details`, {
      headers: { Authorization: "Bearer " + token },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};
