import React, { useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";

function UserMenu({ isAdmin, currentUser }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    navigate("/");
    localStorage.setItem("admin", "");
    window.location.reload();
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex items-center gap-3 cursor-pointer shadow-sm p-2 rounded-full border-2 border-gray-800"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <AiOutlineMenu />
        <img
          className="rounded-full"
          src={currentUser && currentUser.imageUrl}
          alt="profile"
          width="30"
          height="30"
        />
      </div>

      {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
          <div className="py-2">
            {isAdmin ? (
              <Link
                to="/dashboard"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Dashboard
              </Link>
            ) : (
              <>
                <Link
                  to="/account/bookings"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Bookings
                </Link>
                {/* <Link
                  to="/account/messages"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Messages
                </Link>
                <Link
                  to="/account/review"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Reviews
                </Link> */}
              </>
            )}
            <Link
              to="/account"
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Profile
            </Link>
            <button
              onClick={onOpen}
              className="block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100"
            >
              Logout
            </button>
          </div>
        </div>
      )}
      <Modal
        backdrop="blur"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        classNames={{
          backdrop:
            "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Logout</ModalHeader>
              <ModalBody>
                <p>Are you sure you want to logout?</p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={onClose}>
                  Close
                </Button>
                <Button color="danger" onPress={onClose} onClick={handleLogout}>
                  Logout
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

export default UserMenu;
