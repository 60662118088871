import React from 'react'

const NotListings = ({title}) => {
    return (
        <div className="flex flex-col justify-center items-center">
            <img
                src="https://img.freepik.com/free-vector/hand-drawn-tourists-with-baggage_23-2149067034.jpg?t=st=1716546286~exp=1716549886~hmac=af8ca5d06b3f178a1c4b0c9e553a171416f0500340e9b44a3659a5d7fe4bef5a&w=740"
                width={250}
                height={250}
            />
            <div className="tracking-widest mt-1">
                <span className="text-gray-500 text-xl">
                   {title}
                </span>
            </div>
        </div>
    )
}

export default NotListings