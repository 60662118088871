import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsFillPersonFill, BsCalendarFill } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { getAdminDetails } from "../api/UserApi";

function ProfileNav() {
  const [isAdmin, setIsAdmin] = useState(false);
  const { pathname } = useLocation();
  let subpage = pathname.split("/")[2];
  if (subpage === undefined) {
    subpage = "profile";
  }

  function linkClasses(type = null) {
    let classes =
      "nav-link py-3 px-4 rounded-pill mt-4 flex justify-center gap-1 items-center";
    if (type === subpage) {
      classes += " bg-primary text-white";
    } else {
      classes += " bg-light";
    }
    return classes;
  }

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const adminDetails = await getAdminDetails();
        console.log("add", adminDetails);
        if (adminDetails && adminDetails.user.role === "Admin") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, []);

  return (
    <nav className="d-flex justify-content-center mb-4 rounded-pill">
      <Link className={linkClasses("profile")} to="/account">
        <BsFillPersonFill className="me-2" />
        My Profile
      </Link>
      {!isAdmin && (
        <Link className={linkClasses("bookings")} to="/account/bookings">
          <BsCalendarFill className="me-2" />
          My Bookings
        </Link>
      )}
    </nav>
  );
}

export default ProfileNav;
