import React from "react";
import { facilities } from "../../assets/data/data";

function Amenities({ listing }) {
  const amenities = listing?.amenities || [];

  if (amenities.length === 0) {
    return null;
  }

  return (
    <div
      className="border-2 border-gray-200 p-3 m-1 mt-4"
      style={{ borderRadius: "1rem" }}
    >
      <h3 className="mb-3">
        <strong>What this place offers</strong>
      </h3>
      <ul className="grid grid-cols-1 xl:grid-cols-3 gap-3 text-sm text-neutral-700 dark:text-neutral-300 ">
        {amenities.flatMap((amenity, index) =>
          amenity.split(",").map((item, idx) => {
            const matchedFacility = facilities.find(
              (facility) => facility.name.trim() === item.trim()
            );
            if (!matchedFacility) {
              return null; // Skip if no matching facility found
            }
            return (
              <li
                key={index + "-" + idx}
                className="flex items-center gap-2 space-x-3"
              >
                {matchedFacility.icon} {item.trim()}
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
}

export default Amenities;
