import React, { useEffect, useState } from "react";
import ProfileNav from "../shared/ProfileNav";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { useGetMyUser } from "../api/UserApi";
import "../styles/confirmationToast.scss";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import SkeletonLoading from "../components/Loaders/Skeleton";

function UserListing() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const { currentUser } = useGetMyUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedListingId, setSelectedListingId] = useState(null);
  const token = localStorage.getItem("admin");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchListings = async (token) => {
      try {
        setIsLoading(true);
        const userId = currentUser ? currentUser._id : null;
        if (!userId) {
          toast.error("Please log in to view your listings", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        const response = await axios.get(`${API_BASE_URL}/listing`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        console.log("API Response:", response.data);
        setListings(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch listings:", error);
        setIsLoading(false);
      }
    };

    if (currentUser) {
      fetchListings();
    }
  }, [currentUser, API_BASE_URL]);

  const handleDeleteListing = async (id) => {
    setSelectedListingId(id);
    onOpen();
  };

  const deleteListing = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/listing/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setListings((prevListings) =>
        prevListings.filter((listing) => listing._id !== id)
      );
      // Display success message using toast
      toast.success("Listing deleted successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Failed to delete listing:", error);
    }
  };

  const handleUpdateListing = async (id) => {
    navigate(`/manage-listings/update/${id}`);
  };

  // if (isLoading) {
  //   return (

  //   );
  // }

  return (
    <>
      <div className="container mt-5">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-start text-lg font-semibold ">My Listings</h1>
          <Link to={"/manage-listings/new"} className="text-white">
          <Button
            color="primary"
            variant="solid"
            size="sm"
            startContent={<AiOutlinePlus />}
          >
           
              Add
          
          </Button>
          </Link>
        </div>

        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            <Table className="mb-5">
              <TableHeader>
                <TableColumn>Photo</TableColumn>
                <TableColumn>Title</TableColumn>
                <TableColumn>City</TableColumn>
                <TableColumn>Province</TableColumn>
                <TableColumn>Country</TableColumn>
                <TableColumn>Price</TableColumn>
                <TableColumn>Actions</TableColumn>
              </TableHeader>
              <TableBody>
                {listings &&
                  listings.map((listing, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Link
                          to={`/listings/${listing._id}`}
                          style={{ cursor: "pointer" }}
                          className="text-decoration-none"
                        >
                          <img
                            src={listing.signedUrls[0]}
                            alt={listing.title}
                            className="rounded-md object-cover"
                            style={{ width: "4rem", height: "4rem" }}
                          />
                        </Link>
                      </TableCell>
                      <TableCell>{listing.title}</TableCell>
                      <TableCell>{listing.city}</TableCell>
                      <TableCell>{listing.province}</TableCell>
                      <TableCell>{listing.country}</TableCell>
                      <TableCell>
                        $
                        {parseFloat(
                          listing.pricePerNight["$numberDecimal"]
                        ).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        <Button
                          size="sm"
                          variant="ghost"
                          color="primary"
                          onClick={() => handleUpdateListing(listing._id)}
                        >
                          Update
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          color="danger"
                          onClick={() => handleDeleteListing(listing._id)}
                          className="ml-2"
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </>
        )}
      </div>

      <Modal backdrop="blur" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Delete Listing
              </ModalHeader>
              <ModalBody>
                <p>Are you sure you want to delete this Listing?</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="danger"
                  onPress={onClose}
                  onClick={() => deleteListing(selectedListingId)}
                >
                  Delete
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default UserListing;
