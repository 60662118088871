import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <footer className="text-center text-lg-start bg-body-tertiary text-muted border-bottom shadow pt-3 border-t border-2 border-gray-600">

      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Support</h6>
              <p>
                <a className="text-reset">
                  Help Centre   
                </a>
              </p>
              <p>
                <a className="text-reset">AirCover</a>
              </p>
              <p>
                <a className="text-reset">Anti-discrimination</a>
              </p>
              <p>
                <a className="text-reset">Disability support</a>
              </p>
              <p>
                <a className="text-reset">Cancellation options</a>
              </p>
              <p>
                <a className="text-reset">Report</a>
              </p>
              <p>
                <a className="text-reset">Disability neighbourhood concern</a>
              </p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Hosting</h6>
              <p>
                <a href="#!" className="text-reset">
                  Pricing
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Settings
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Orders
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Help
                </a>
              </p>
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <i className="fas fa-home me-3"></i> New York, NY 10012, US
              </p>
              <p>
                <i className="fas fa-envelope me-3"></i>
                info@example.com
              </p>
              <p>
                <i className="fas fa-phone me-3"></i> + 01 234 567 88
              </p>
              <p>
                <i className="fas fa-print me-3"></i> + 01 234 567 89
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="text-center p-4">
        © 2024 Copyright  
        <a className="text-reset fw-bold">Hotel Bookings</a>
      </div>
    </footer>
  );
}

export default Footer;
