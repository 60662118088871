import React from 'react'

const Dashboard = () => {
    return (
        <div className="container-fluid mt-5">
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-start text-lg font-semibold ">Dashboard</h1>               
            </div>
        </div>
    )
}

export default Dashboard