import React, { useState } from "react";
import { differenceInCalendarDays } from "date-fns";
import "../../styles/ListingDetails.scss";
import Calendar from "./Calendar";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Input } from "@nextui-org/react";

function BookingWidget({ listing }) {
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState(1);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let numberOfNights = 0;
  if (checkIn && checkOut) {
    numberOfNights = differenceInCalendarDays(
      new Date(checkOut),
      new Date(checkIn)
    );
  }

  const pricePerNight = parseFloat(
    listing.pricePerNight["$numberDecimal"] || 0
  );
  const totalPrice = numberOfNights * pricePerNight;

  const handleReserve = async () => {
    if (!checkIn || !checkOut) {
      toast.error("Please select dates for your stay 🏠");
      return;
    }

    if (numberOfGuests > listing.guestCount || numberOfGuests < 1) {
      toast.error(
        "Sorry, you exceed the amount of guests the host has allowed 😞"
      );
      return;
    }

    // if (!listing._id || !currentUser) {
    //   toast.error("Booking cannot be processed. Check listing details or user authentication.");
    //   return;
    // }

    const data = [
      {
        // userId: currentUser._id,
        listingId: listing._id.toString(),
        title: listing.title,
        host: listing?.host ? listing?.host?.username : "Admin",
        image: listing.signedUrls[0],
        checkInDate: checkIn,
        checkOutDate: checkOut,
        price: totalPrice,
        bookingStatus: "Confirmed",
        quantity: 1,
        numberOfGuests: numberOfGuests,
      },
    ];
    console.log("check items", data);

    // try {
    //   const { data } = await axios.post(
    //     `${process.env.REACT_APP_API_BASE_URL}/booking/payment`,
    //     { items }
    //   );
    //   window.location = data.url; // Redirect to Stripe Checkout
    // } catch (error) {
    //   console.error("Error creating Stripe session:", error);
    //   toast.error("Failed to create booking session.");
    // }
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/booking`,
        { data }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Booking Enquiry sent successfully.");
        setMessage("Booking Enquiry sent successfully.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error", error);
      toast.error(
        "Booking cannot be processed. Check listing details or user authentication."
      );
      setLoading(false);
    }
  };

  return (
    <div
      className="booking-info bg-white p-2 shadow w-75 mx-5"
      style={{ maxHeight: "550px" }}
    >
      <h5 className="my-3 font-semibold">${pricePerNight.toFixed(2)} /night</h5>
      <div className="booking-inputs border">
        <div className="px-2 py-1">
          <Calendar
            listing={listing}
            onCheckInChange={(date) => setCheckIn(date)}
            onCheckOutChange={(date) => setCheckOut(date)}
          />
        </div>
        <div className="px-2 py-1 mb-2">
          <label className="ml-2">Number of Guests</label>
          <Input
            key="default"
            type="number"
            color="default"
            className="max-w-[220px]"
            defaultValue={1}
            value={numberOfGuests}
            onChange={(ev) => setNumberOfGuests(parseInt(ev.target.value, 10))}
          />
        </div>
      </div>
      <div className="text-center">
        <Button
          color="danger"
          className="my-4 px-4 py-3 rounded-pill w-75 h-24"
          onClick={handleReserve}
        >
          {loading ? "Sending...." : "Send Enquiry"}
        </Button>
      </div>
      {numberOfNights > 0 && (
        <div className="mt-4 border rounded-md h-auto p-3">
          <div className="d-flex flex-row justify-content-center align-items-center px-3">
            <p>
              {pricePerNight.toFixed(2)} X {numberOfNights} nights =$
              {(numberOfNights * pricePerNight.toFixed(2)).toFixed(2)}
            </p>
          </div>
        </div>
      )}
     
    </div>
  );
}

export default BookingWidget;
