import React, { useEffect } from "react";
import Routers from "./routes/Routers";
import { getAdminDetails } from "./api/UserApi";

function App() {
  useEffect(() => {
    getAdminDetails();
  }, []);
  return <Routers />;
}

export default App;
