import React, { useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ListingCard.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight, FaStar } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { Spinner } from "@nextui-org/react";
import { Skeleton } from "@nextui-org/react";

function ListingCard({ listing }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const {
    title,
    pricePerNight,
    type,
    signedUrls,
    city,
    province,
    country,
    isFavorite,
    averageRate,
  } = listing;
  const price = parseFloat(pricePerNight["$numberDecimal"]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="shadow-md card-css hover:opacity-75">
      <Link to={`/listings/${listing._id}`} className="listing-card-view">
        <Card
          className="border-0 shadows mb-2"
          style={{ borderRadius: "10px" }}
        >
          {/* Photo */}
          <div>
            <Slider {...settings}>
              {signedUrls.map((url, index) => (
                <div key={index}>
                  {!imageLoaded && (
                    <div className="m-2 mt-5 w-full flex justify-center items-center gap-3">
                      <Skeleton
                        className="flex rounded-full w-12 h-12"
                        style={{ width: "10rem", height: "10rem" }}
                      />
                    </div>
                  )}

                  <img
                    src={url}
                    alt=""
                    className="listing_img"
                    style={{
                      height: "275px",
                      width: "100%",
                      display: imageLoaded ? "block" : "none",
                    }}
                    onLoad={() => setImageLoaded(true)}
                  />
                </div>
              ))}
            </Slider>
            {isFavorite && (
              <span className="position-absolute top-0 end-0 bg-light text-dark p-2 rounded-pill m-2 fw-700">
                Guest Favorite
              </span>
            )}
          </div>
        </Card>

        {imageLoaded ? (
          <CardBody>
            {/* Location */}
            <div className="flex items-center justify-between">
              <h6 className="listing-card-title capitalize truncate">
                {title}
              </h6>
            </div>
            <p className="listing-card-price">{type}</p>
            {/* Title */}
            <div className="flex gap-1 items-center mt-2">
              <IoLocationSharp />
              <span className="listing-card-location flex items-center gap-1">
                {city}, {province}, {country}
              </span>
            </div>
            {/* Type */}

            <div className="card_bottom flex items-center justify-between mt-3">
              <h6 className="listing-card-price">
                ${price.toFixed(2)}
                <span className="font-light" style={{ fontSize: "12px" }}>
                  /night
                </span>
              </h6>

              <span className="listing_rating flex items-center gap-1">
                <FaStar style={{ color: "#FFD700" }} />
                {averageRate.toFixed(1)}
              </span>
            </div>
          </CardBody>
        ) : (
          <div className="max-w-[300px] w-full flex items-center gap-3">
            <div className="m-2 mt-3  w-full flex justify-center items-center gap-3">
              <div className="w-full mt-4 pb-3 flex flex-col gap-2">
                <Skeleton className="h-3 w-4/5 rounded-lg mt-2" />
                <Skeleton className="h-3 w-4/5 rounded-lg mt-2" />
                <Skeleton className="h-3 w-4/5 rounded-lg mt-2" />
                <Skeleton className="h-3 w-4/5 rounded-lg mt-2" />
              </div>
            </div>
          </div>
        )}
      </Link>
    </div>
  );
}

export default ListingCard;
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} next-arrow`}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        marginRight: "24px",
        cursor: "pointer",
        outline: "none",
        zIndex: "1",
      }}
      onClick={onClick}
      tabIndex={0}
    >
      <FaChevronLeft />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} prev-arrow`}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        marginLeft: "24px",
        cursor: "pointer",
        outline: "none",
        zIndex: "1",
      }}
      onClick={onClick}
      tabIndex={0}
    >
      <FaChevronRight />
    </div>
  );
}
