import React from "react";

function Booking() {
  return (
    <div className="mt-4 pt-4">
      <h2>Booking - Stripe API</h2>
    </div>
  );
}

export default Booking;
