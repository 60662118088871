import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Slider,
} from "@nextui-org/react";
import { types } from "../../assets/data/data";

export default function ListingFilter({ setSelectedFilter }) {
  const [bedroom, setBedroom] = useState();
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedStars, setSelectedStars] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState();
  const [selectedPriceRange, setSelectedPriceRange] = useState([0, 20000]);
  const [bathroom, setBathroom] = useState();
  const [bed, setBed] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleListingTypeChange = (event) => {
    const listingType = event.target.value;
    setSelectedTypes((prevTypes) =>
      event.target.checked
        ? [...prevTypes, listingType]
        : prevTypes.filter((listing) => listing !== listingType)
    );
  };
  const handleStarsChange = (event) => {
    const starRating = event.target.value;

    setSelectedStars((prevStars) =>
      event.target.checked
        ? [...prevStars, starRating]
        : prevStars.filter((star) => star !== starRating)
    );
  };

  const handleFacilityChange = (event) => {
    const facility = event.target.value;

    setSelectedFacilities((prevFacilities) =>
      event.target.checked
        ? [...prevFacilities, facility]
        : prevFacilities.filter((prevFacility) => prevFacility !== facility)
    );
  };

  const handleBedroomChange = (event) => {
    setBedroom(event.target.value);
  };
  const handleBedChange = (event) => {
    setBed(event.target.value);
  };
  const handleBathroomChange = (event) => {
    setBathroom(event.target.value);
  };
  const handlePriceChange = (event) => {
    setSelectedPrice(
      event.target.value ? parseInt(event.target.value) : undefined
    );
  };

  const handlePriceRangeChange = (value) => {
    setSelectedPriceRange(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSelectedFilter({
      listingTypes: selectedTypes,
      facilities: selectedFacilities,
      averageRate: selectedStars,
      pricePerNight: selectedPrice,
      bedroom: bedroom,
      bathroom: bathroom,
      bed: bed,
    });
  };

  const clearTerms = () => {
    setSelectedTypes([]);
    setSelectedFacilities([]);
    setSelectedStars([]);
    setSelectedPrice("");
    setBedroom("");
    setBathroom(""); 
    setBed(""); 
  };

  return (
    <React.Fragment>
      <Button color="danger" onPress={onOpen}>
        Filter
      </Button>
      <Modal backdrop="blur" isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Filter</ModalHeader>
              <ModalBody>
                <h5 className="text-md font-semibold mb-2">Type of listing:</h5>
                <div className="flex gap-5 items-center">
                  {types.map((listingType) => (
                    <label
                      className="flex items-center space-x-2 gap-2"
                      key={listingType.name}
                      style={{
                        marginRight: "15px",
                        fontSize: "15px",
                        marginBottom: "20px",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="rounded "
                        value={listingType.name}
                        checked={selectedTypes.includes(listingType.name)}
                        onChange={handleListingTypeChange}
                      />
                      <span>{listingType.name}</span>
                    </label>
                  ))}
                </div>
                {/* need fix backend search query */}
                {/* <h4 className="text-md font-semibold mb-2">Facilities</h4>
                {facilities.map((facility) => (
                  <label
                    className="flex items-center space-x-2"
                    key={facility.name}
                  >
                    <input
                      type="checkbox"
                      className="rounded"
                      value={facility.name}
                      checked={selectedFacilities.includes(facility.name)}
                      onChange={handleFacilityChange}
                    />
                    <span>{facility.name}</span>
                  </label>
                ))} */}
                <h5 className="text-md font-semibold mb-2">Listing Rating</h5>
                <div className="flex gap-3 items-center">
                  {["5", "4", "3", "2", "1"].map((star) => (
                    <label
                      className="flex items-center space-x-2 gap-1"
                      style={{
                        marginRight: "15px",
                        fontSize: "15px",
                        marginBottom: "20px",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="rounded"
                        value={star}
                        checked={selectedStars.includes(star)}
                        onChange={handleStarsChange}
                      />
                      <span>{star} Stars</span>
                    </label>
                  ))}
                </div>
                <h5 className="text-md font-semibold mb-2">Rooms and Beds</h5>
                <div className="flex items-center gap-2 justify-center mb-2">
                  <form className="max-w-sm mx-auto">
                    <label
                      for="countries"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Bedrooms
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={bedroom}
                      label="Bedroom"
                      onChange={handleBedroomChange}
                    >
                      <option selected>Choose Bedrooms</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3+</option>
                    </select>
                  </form>
                  <form className="max-w-sm mx-auto">
                    <label
                      for="countries"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Bathrooms
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={bathroom}
                      label="Bathroom"
                      onChange={handleBathroomChange}
                    >
                      <option selected>Choose Bathrooms</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3+</option>
                    </select>
                  </form>
                  <form className="max-w-sm mx-auto">
                    <label
                      for="countries"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Beds
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={bed}
                      label="Beds"
                      onChange={handleBedChange}
                    >
                      <option selected>Choose Beds</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3+</option>
                    </select>
                  </form>
                </div>
                <h5 className="text-md font-semibold mb-2"> Max Price</h5>
                <select
                  className="p-2 border rounded-md w-full"
                  value={selectedPrice}
                  onChange={handlePriceChange}
                >
                  <option
                    value=""
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Select Max Price
                  </option>
                  {[
                    100, 300, 500, 800, 1000, 1500, 3000, 5000, 10000, 12000,
                    15000, 20000,
                  ].map((price) => (
                    <option value={price}>{price}</option>
                  ))}
                </select>
                {/* <Slider
                  label="Price Range"
                  step={50}
                  minValue={0}
                  maxValue={20000}
                  defaultValue={selectedPriceRange}
                  onChange={handlePriceRangeChange}
                  formatOptions={{ style: "currency", currency: "USD" }}
                  className="max-w-xl"
                  color="danger"
                /> */}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  variant="light"
                  onPress={clearTerms}
                >
                  Clear Filters
                </Button>
                <Button color="secondary" onPress={onClose}>
                  Close
                </Button>
                <Button color="danger" onPress={onClose} onClick={handleSubmit}>
                  Show Results
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}
