import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSearchContext } from "../../auth/SearchContext";
import { useNavigate } from "react-router-dom";
import { Input } from "@nextui-org/react";
// import "../../styles/Search.scss";

function Search() {
  const navigate = useNavigate();
  const search = useSearchContext();

  const [destination, setDestination] = useState(search.destination);
  const [checkIn, setCheckIn] = useState(new Date(search.checkIn));
  const [checkOut, setCheckOut] = useState(new Date(search.checkOut));
  const [guestCount, setGuestCount] = useState(search.guestCount);

  const handleSubmit = (event) => {
    event.preventDefault();
    search.saveSearchValues(destination, checkIn, checkOut, guestCount);
    navigate("/search");
  };
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  return (
    <form
      className="searchbar flex items-center gap-2 border-2 border-gray-200 rounded-full px-4 py-1 shadow-sm"
      onSubmit={handleSubmit}
    >
      <div className="w-full flex flex-col p-1 border-r border-gray-600">
        <span className="ml-2">Where to go</span>
        <Input
          type="text"
          variant="flat"
          placeholder="Search"
          value={destination || ""}
          size="sm"
          onChange={(e) => setDestination(e.target.value)}
        />
      </div>
      <div className="w-full">
        <span>Check-in</span>
        <DatePicker
          placeholderText="Add Date"
          className="w-full bg-white p-1 border-0"
          wrapperClassName="min-w-10"
          selected={checkIn}
          onChange={(date) => setCheckIn(date)}
          selectsStart
          startDate={checkIn}
          endDate={checkOut}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      <div className="w-full">
        <span>Check-out</span>
        <DatePicker
          placeholderText="Add Date"
          className="w-full bg-white p-1 border-0"
          wrapperClassName="min-w-25"
          selected={checkOut}
          onChange={(date) => setCheckOut(date)}
          selectsEnd
          startDate={checkIn}
          endDate={checkOut}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>

      <div className="flex flex-col w-full">
        <span>Add Guests</span>
        <Input
          type="number"
          defaultValue={1}
          value={guestCount || ""}
          size="sm"
          onChange={(e) => setGuestCount(parseInt(e.target.value))}
        />
      </div>

      <div className="m-2">
        <button
          className="rounded-full text-white shadow-lg p-2 "
          type="submit"
          style={{
            backgroundColor: "#FF5A5F",
            border: "none",
          }}
        >
          <BiSearch size={18} />
        </button>
      </div>
    </form>
  );
}

export default Search;
