import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import Search from "./Search";
import UserMenu from "./UserMenu";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import avatar from "../../assets/images/avatar.jpg";
import { getAdminDetails, useGetMyUser } from "../../api/UserApi";

function Header() {
  const { currentUser } = useGetMyUser();
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const adminDetails = await getAdminDetails();
        console.log("add", adminDetails);
        if (adminDetails && adminDetails.user.role === "Admin") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, []);

  return (
    <div className="fixed top-0 z-50 w-full bg-white p-3 px-5 border-2 border-yellow-500">
      <nav className="flex items-center justify-between">
        <div>
          <Link to={"/"} style={{ cursor: "pointer" }}>
            <img
              src={logo}
              className="pointer"
              width="100"
              alt="logo"
              style={{ cursor: "pointer" }}
            />
          </Link>
        </div>
        {!isAdmin && <Search />}
        {/* {isAdmin && (
          <UserMenu isAdmin={isAdmin} currentUser={currentUser}/>
        ) : (
          <div
            className="p-2 flex flex-row items-center gap-3 rounded-full shadow-sm"
            onClick={() => navigate("/admin-login")}
            style={{ cursor: "pointer" }}
          >
            <AiOutlineMenu />
            <img
              className="rounded-full"
              src={avatar}
              alt="profile"
              width="30"
              height="30"
            />
          </div>
        )} */}
        <div>
          {isAdmin && <UserMenu isAdmin={isAdmin} currentUser={currentUser} />}
        </div>
      </nav>
    </div>
  );
}

export default Header;
