import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAdminDetails } from "../api/UserApi";
import { Button, Input } from "@nextui-org/react";

const AdminSignIn = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    role: "Admin",
  });

  console.log("formData", formData);

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      toast.error("Enter Valid Mail Address & Password");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/users/login`,
        formData
      );
      if (response) {
        console.log("response", response);
        const token = response.data.token;
        localStorage.setItem("admin", token);
        toast.success(response?.data.message);
        getAdminDetails();
        navigate("/");
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.error("Error", error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-center items-center mt-5">
        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
          <div className="text-center mb-4">
            <h1 className="mt-3 text-2xl">Sign in</h1>
          </div>
          <div className="card p-4 shadow-sm">
            <form onSubmit={handleSubmit} method="POST">
              <div className="mb-3">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  label="Email Address"
                  value={formData.email}
                  onChange={handleOnChange}
                  required
                />
              </div>
              <div className="mb-3">
                <Input
                  id="password"
                  name="password"
                  label="Password"
                  type={showPass ? "text" : "password"}
                  value={formData.password}
                  onChange={handleOnChange}
                  required
                />
              </div>
              <div className="mb-3 form-check ml-2">
                <input
                  id="show-password"
                  type="checkbox"
                  className="form-check-input"
                  checked={showPass}
                  onChange={() => setShowPass(!showPass)}
                />
                <label htmlFor="show-password" className="form-check-label">
                  Show Password
                </label>
              </div>
              <div className="d-grid">
                <Button color="danger" type="submit">
                  Sign in
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSignIn;
