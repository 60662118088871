import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Sidebar.css";

const Sidebar = () => {
    const [activeTab, setActiveTab] = useState("/dashboard");

    const handleSetActiveTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="h-[100vh] main-sidebar flex flex-col shadow-md">
            <div className="flex flex-col flex-1 overflow-y-auto bg-gray-100">
                <nav className="flex-1 justify-start sidebar-container">
                    <Link
                        to="/dashboard"
                        className={`sidebar-links flex items-center py-2 text-gray-800 transition duration-300 ease-in-out ${activeTab === "/dashboard" ? "active-tab" : ""
                            }`}
                        onClick={() => handleSetActiveTab("/dashboard")}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        Dashboard
                    </Link>
                    <Link
                        to="/manage-listings"
                        className={`sidebar-links flex mt-3 items-center py-2 text-gray-800 transition duration-300 ease-in-out ${activeTab === "/manage-listings" ? "active-tab" : ""
                            }`}
                        onClick={() => handleSetActiveTab("/manage-listings")}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                        </svg>
                        Manage Listings
                    </Link>
                    <Link
                        to="/manage-users"
                        className={`sidebar-links flex mt-3 items-center py-2 text-gray-800 transition duration-300 ease-in-out ${activeTab === "/manage-users" ? "active-tab" : ""
                            }`}
                        onClick={() => handleSetActiveTab("/manage-users")}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                        </svg>
                        Manage Users
                    </Link>
                </nav>
            </div>
        </div>
    );
};

export default Sidebar;
