import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getAdminDetails } from "../api/UserApi";
import "../styles/admin.css";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import SkeletonLoading from "../components/Loaders/Skeleton";

function Admin() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteId, setDeleteId] = useState(null);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("admin");
      const response = await axios.get(`${API_BASE_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUsers(response.data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      toast.error("Failed to fetch users. Please try again.");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const adminDetails = await getAdminDetails();

      if (adminDetails) {
        setUsers(adminDetails.users);
      } else {
        toast.error("You are not authorized to access this page.");
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const handleDeleteUser = async (userId) => {
    setDeleteId(userId);
    onOpen();
  };

  const deleteUser = async (userId) => {
    try {
      const token = localStorage.getItem("admin");
      await axios.delete(`${API_BASE_URL}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUsers(users.filter((user) => user._id !== userId));
      toast.success("User deleted successfully");
    } catch (error) {
      console.error("Failed to delete user:", error);
      toast.error("Failed to delete user. Please try again.");
    }
  };

  const openEditModal = (user) => {
    setEditUser(user);
    toggleModal(true);
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem("admin");
      await axios.put(
        `${API_BASE_URL}/users/${editUser._id}`,
        {
          username: editUser.username,
          email: editUser.email,
          phone: editUser.phone,
          role: editUser.role,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("User data updated successfully!");
      fetchUsers();
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.error("Failed to update user data. Please try again.");
    }
    toggleModal(false);
  };

  const handleCancelClick = () => {
    toggleModal(false);
  };

  const toggleModal = (isOpen) => {
    const modal = document.getElementById("myModal");
    if (modal) {
      modal.style.display = isOpen ? "block" : "none";
    }
  };

  // if (loading) {
  //   return <SkeletonLoading />;
  // }

  return (
    <>
      <div className="container-fluid mt-5">
        {/* <Sidebar /> */}
        <h1 className="text-start text-lg font-semibold mb-4">
          Manage User Account
        </h1>
        <div className="adminPanel">
          {loading ? (
            <SkeletonLoading />
          ) : (
            <>
              <Table className="mb-5">
                <TableHeader>
                  <TableColumn>Username</TableColumn>
                  <TableColumn>Email</TableColumn>
                  <TableColumn>Phone</TableColumn>
                  <TableColumn>Role</TableColumn>
                  <TableColumn>Actions</TableColumn>
                </TableHeader>
                <TableBody>
                  {users &&
                    users.map((user, i) => (
                      <TableRow key={i}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.phone}</TableCell>
                        <TableCell>{user.role}</TableCell>
                        <TableCell>
                          <Button
                            size="sm"
                            variant="ghost"
                            color="primary"
                            onClick={() => openEditModal(user)}
                          >
                            Update
                          </Button>
                          <Button
                            size="sm"
                            variant="ghost"
                            color="danger"
                            onClick={() => handleDeleteUser(user._id)}
                            className="ml-2"
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </>
          )}

          {/* Modal */}
          <div id="myModal" className="modal1">
            <div className="modal1-content">
              <form className="modal1-form">
                <h1>Edit User Account</h1>
                <div className="box">
                  <label htmlFor="username">Username:</label>
                  <input
                    className="modal1-input"
                    type="text"
                    id="username"
                    value={editUser?.username || ""}
                    onChange={(e) =>
                      setEditUser({ ...editUser, username: e.target.value })
                    }
                  />
                </div>
                <div className="box">
                  <label htmlFor="email">Email:</label>
                  <input
                    className="modal1-input"
                    type="text"
                    id="email"
                    value={editUser?.email || ""}
                    onChange={(e) =>
                      setEditUser({ ...editUser, email: e.target.value })
                    }
                  />
                </div>
                <div className="box">
                  <label htmlFor="phone">Phone:</label>
                  <input
                    className="modal1-input"
                    type="text"
                    id="phone"
                    value={editUser?.phone || ""}
                    onChange={(e) =>
                      setEditUser({ ...editUser, phone: e.target.value })
                    }
                  />
                </div>
                <div className="box">
                  <label htmlFor="role">Role:</label>
                  <select
                    className="modal1-input"
                    id="role"
                    value={editUser?.role || ""}
                    onChange={(e) =>
                      setEditUser({ ...editUser, role: e.target.value })
                    }
                  >
                    <option value="Guest">Guest</option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>
                <div className="modal1Btn_container">
                  <button
                    className="modal1_btn"
                    type="button"
                    onClick={handleSaveClick}
                  >
                    Save
                  </button>
                  <button
                    className="modal1_btn"
                    type="button"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal backdrop="blur" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Remove User
              </ModalHeader>
              <ModalBody>
                <p>Are you sure you want to remove this User?</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="danger"
                  onPress={onClose}
                  onClick={() => deleteUser(deleteId)}
                >
                  Remove
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default Admin;
