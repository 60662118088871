import React, { useState, useEffect } from "react";
import avatar from "../../assets/images/avatar.jpg";
import { useGetMyUser } from "../../api/UserApi";
import { FiCalendar } from "react-icons/fi";
import "../../styles/ListingDetails.scss";
import { Spinner } from "@nextui-org/react";
import { LuUsers2 } from "react-icons/lu";
import { FaBed, FaBath } from "react-icons/fa6";
import { MdBedroomParent } from "react-icons/md";

function ListingInfo({ listing }) {
  // const [avgRating, setAvgRating] = useState(0);
  // const [reviews, setReviews] = useState([]);
  const [userPicture, setUserPicture] = useState(null);
  const [username, setUsername] = useState(null);
  const { currentUser, isLoading } = useGetMyUser();

  useEffect(() => {
    if (currentUser) {
      setUserPicture(currentUser.imageUrl);
      setUsername(currentUser.username);
    }
  }, [currentUser]);

  // if (!listing || isLoading) {
  //   return (
  //     <Spinner
  //       color="danger"
  //       className="flex justify-center items-center mt-5"
  //     />
  //   );
  // }

  const {
    type,
    city,
    country,
    guestCount,
    bedroomCount,
    bedCount,
    bathroomCount,
    description,
    // averageRate,
  } = listing;

  console.log("listing", listing);

  return (
    <div
      className="border-2 border-gray-200 p-3 m-1 "
      style={{ borderRadius: "1rem" }}
    >
      <h3 className="fw-bold mt-4">
        {type} in {city}, {country}
      </h3>

      {/* Max Guest | Bedroom | Bed | Bathroom */}
      <div>
        <span className="d-flex align-items-center gap-3 mt-2">
          <div className="flex gap-1 items-center">
            <LuUsers2 /> {guestCount} Guests
          </div>
          <div className="flex gap-1 items-center">
            <MdBedroomParent /> | {bedroomCount} Bedrooms |
          </div>
          <div className="flex gap-1 items-center">
            <FaBed /> {bedCount} Beds |
          </div>
          <div className="flex gap-1 items-center">
            <FaBath /> {bathroomCount} Baths
          </div>
        </span>
      </div>
      {/* Average Ratings */}
      <div className="border-bottom pb-3">
        {/* <span className="d-flex align-items-center gap-1">
          <i className="bi bi-star-fill"></i>
          {avgRating === 0 ? null : avgRating}
          {totalRating === 0 ? (
            "Not yet rated"
          ) : (
            <span>| ({reviews.length})</span>
          )}
        </span> */}
      </div>
      {/* Host */}
      {/* ToDo Retrieve Users Name and Photo */}
      <div className="mt-3 flex flex-row border-bottom pb-3 items-center">
        <img
          className="rounded-circle"
          src={avatar}
          alt="profile"
          width="40"
          height="40"
        />
        <h6 className="mx-3">
          Hosted By {listing.host ? listing.host.username : "Host"}
        </h6>
      </div>

      {/* Extra Information */}
      <div className="mt-3 flex flex-row border-bottom pb-3">
        <h5 className="flex gap-2 items-center">
          <FiCalendar /> Free cancellation for 48 hours
        </h5>
        {/* ToDo: */}
        {/* Put 1 feature of the place */}
        {/* Put 1 feature about the user */}
      </div>

      {/* Description */}

      <div className="mt-3 g-2 pb-3 px-4">{description}</div>

      {/* Bedroom*/}
      {/* ToDo: add type of bed: king, queen, double */}
      {/* <div className="mt-3 d-flex flex-column border-bottom pb-3">
        <h4 className="mb-3">Where you'll sleep</h4>
        <div className="bedroom-photo">
          <img src={bedroomPhoto} alt="" className="listing-photo" />
        </div>
        <h6 className="pt-3">Bedroom</h6>
        <p>{bedCount} Queen size bed</p>
      </div> */}
    </div>
  );
}

export default ListingInfo;
